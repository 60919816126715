import React, { Component ,lazy,Suspense} from 'react';
import { Layout,BackTop,Card} from 'antd';
import {
  Routes,
  Route,
} from 'react-router-dom'

import './App.css' 

import NotFound from './NotFound';
import Loading from './components/Loading';
import Login from './contents/Login';
import Home from './contents/Home';
const Birthday2024= lazy(()=> import('./contents/Birthday2024'))


const { Content} = Layout;
export default class App extends Component {
  render() {
    return (
      <div  style={{ backgroundColor: '#070026'}}>


    

          

          <BackTop />
          <Suspense fallback={<Loading/>}><div className='middle2'>

          
          <Routes>
                <Route index element = {<Login/>}/> 
                <Route path="Login"  element = {<Login/>}/> 
                <Route path="home/*"  element = {<Home/>}/> 
                <Route path="birthday2024"  element = {<Birthday2024/>}/> 
                <Route path="*" element={<NotFound/>} />
          </Routes>
          </div>
          </Suspense>



      </div>
    );
  }
}
