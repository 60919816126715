const initState = {PW:'1314'}
export default function Backurl(PW=initState,action){
	const {type} = action
	switch (type) {
		case 'login': 
			PW={PW:'520'};
			break;
		case 'logout':
			PW=initState
			break;
		default:break;
	}
	return PW
}
