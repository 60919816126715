//统一的header
import React, { Component } from 'react';
import { Header} from 'antd/lib/layout/layout';
import './index.css'
import {connect} from 'react-redux'
import { Menu ,Image,Col, Row,Card,Divider,Typography} from 'antd';

import {Link} from 'react-router-dom'
import {
  FieldTimeOutlined,
  ProjectOutlined,
  FundViewOutlined,
  UnorderedListOutlined, 
  HomeOutlined,
  BookOutlined,
  ExperimentOutlined,
  HeartFilled,
  MailOutlined 
} from '@ant-design/icons';
const { Title } = Typography;
class UHeader extends Component {
 
  render() {
    return <Header className="ant-layout-header" style={{  backgroundColor: '#070026' ,padding: 0 ,height:70 }}> 
     
      <Menu className="ant-layout-header2" mode="horizontal"  theme="dark" style={{  backgroundColor: '#070026' ,padding: 0 ,height:70 }}>
        <Menu.Item key="1" icon={<HomeOutlined style={{fontSize: '30px',color:'pink'}}/>}>
          <Link to="/home/main"><font face="F2" size="10" color="pink">主页</font></Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<FieldTimeOutlined style={{fontSize: '30px',color:'pink'}}/>}>
          <Link to="/home/Timeline"><font face="F2" size="10" color="pink">时间线</font></Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<UnorderedListOutlined style={{fontSize: '30px',color:'pink'}}/>}>
          <Link to="/home/List"><font face="F2" size="10" color="pink">清单</font></Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<HeartFilled twoToneColor="pink" style={{fontSize: '30px',color:'pink'}}/>}>
          <Link to="/home/sex"><font face="F2" size="10" color="pink">色色</font></Link>
        </Menu.Item>

      </Menu>
        
  </Header>;
  }
}

export default connect(
	state => ({
	}),
	{}
)(UHeader)

