//首页
import React, { useEffect, useState } from 'react'
import { Card ,Col, Row,Image,Collapse,Typography,Divider, Button, Progress} from 'antd'
import './index.css'

import {

MinusOutlined, PlusOutlined
} from '@ant-design/icons';
const { Title } = Typography;
export default function Sexpg() {
  const [currentCount, setCurrentCount] = useState(0);
  const totalCount = 1000;

 

  const increase = () => {
    const newCount = currentCount + 1;
    if (newCount <= totalCount) {
      setCurrentCount(newCount);
    }
  };

  const decline = () => {
    const newCount = currentCount - 1;
    if (newCount >= 0) {
      setCurrentCount(newCount);
    }
  };

  const percent = ((currentCount / totalCount) * 100).toFixed(1);

  return (
    <div type="ncentera" style={{ fontFamily: 'F2', color:'red',fontSize: '100px', margin: '10px 0', height:'1000px'}}>
    
      <Divider />
      该页面涉黄无法显示！
    </div>
  );
}
