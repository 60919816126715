//首页
import React , { useState, useEffect }from 'react'
import { Card ,Col, Row,Image,Collapse,Typography,Divider} from 'antd'
import './index.css'
import {Link} from 'react-router-dom'
import {
  RightSquareTwoTone
} from '@ant-design/icons';
const { Title } = Typography;
export default function Mainpg() {
  const { Panel } = Collapse;
  const [timeDiff, setTimeDiff] = useState({
    from2007: { days: 0, hours: 0, minutes: 0, seconds: 0 },
    to2024: { days: 0, hours: 0, minutes: 0, seconds: 0 },
    toNext16th: { days: 0, hours: 0, minutes: 0, seconds: 0 },
  });

  useEffect(() => {
    const startDate1 = new Date('2007-08-31T10:13:14');
    const startDate2 = new Date('2024-01-31T10:50:00');
    const updateDiff = () => {
      const currentDate = new Date();

      // From 2007-09-01 to now
      const diff1 = Math.floor((currentDate - startDate1) / 1000); // in seconds
      const days1 = Math.floor(diff1 / (24 * 60 * 60));
      const hours1 = Math.floor((diff1 % (24 * 60 * 60)) / (60 * 60));
      const minutes1 = Math.floor((diff1 % (60 * 60)) / 60);
      const seconds1 = diff1 % 60;

      // From 2024-02-01 to now
      const diff2 = Math.floor((currentDate - startDate2) / 1000); // in seconds
      const days2 = Math.floor(diff2 / (24 * 60 * 60));
      const hours2 = Math.floor((diff2 % (24 * 60 * 60)) / (60 * 60));
      const minutes2 = Math.floor((diff2 % (60 * 60)) / 60);
      const seconds2 = diff2 % 60;

      // Time to next 16th of the month
      const next16th = new Date(currentDate);
      if (currentDate.getDate() >= 16) {
        next16th.setMonth(next16th.getMonth() + 1);
      }else next16th.setMonth(next16th.getMonth());
      next16th.setDate(16);
      next16th.setHours(0);
      next16th.setMinutes(0);
      next16th.setSeconds(0);
      
      const diff3 = Math.floor((next16th - currentDate) / 1000); // in seconds
      
      const days3 = Math.floor(diff3 / (24 * 60 * 60));
      const hours3 = Math.floor((diff3 % (24 * 60 * 60)) / (60 * 60));
      const minutes3 = Math.floor((diff3 % (60 * 60)) / 60);
      const seconds3 = diff3 % 60;

      setTimeDiff({
        from2007: { days: days1, hours: hours1, minutes: minutes1, seconds: seconds1 },
        to2024: { days: days2, hours: hours2, minutes: minutes2, seconds: seconds2 },
        toNext16th: { days: days3, hours: hours3, minutes: minutes3, seconds: seconds3 },
      });
    };

    updateDiff(); // Initial calculation
    const interval = setInterval(updateDiff, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);
  return (
    <div type="ncentera" className='background2'>
      <p style={{ fontFamily: 'F3', fontSize: '80px',color:'pink', margin: '10px 0',textAlign: 'center' }}>谈一场永远不分手的恋爱</p>
      <Divider />
      <Row align="middle" >
      <Col align='left' span={6} >
      <p style={{ fontFamily: 'F1', fontSize: '50px',color:'pink', margin: '10px 0',textAlign: 'center' }}>沟通日:</p>
      </Col>

        <Col span={15}offset={2}>
<p style={{ fontFamily: 'F4', fontSize: '40px',color:'pink', margin: '10px 0',textAlign: 'center' }}>{timeDiff.toNext16th.days} DAYS {timeDiff.toNext16th.hours} HOURS {timeDiff.toNext16th.minutes} MINS {timeDiff.toNext16th.seconds} S</p> 
        </Col>
      </Row>
      <Divider />
      <p style={{ fontFamily: 'F2', fontSize: '80px',color:'pink', margin: '10px 0',textAlign: 'center' }}>时间会告诉你我有多么爱你</p>
      <Row align="middle" >
      <Col align='left' span={6} >
      <p style={{ fontFamily: 'F1', fontSize: '50px',color:'pink', margin: '10px 0',textAlign: 'center' }}>相识时间:</p>
      <p style={{ fontFamily: 'F1', fontSize: '50px',color:'pink', margin: '10px 0',textAlign: 'center' }}>相恋时间:</p>
      </Col>

        <Col span={15}offset={2}>
        <p style={{ fontFamily: 'F4', fontSize: '40px',color:'pink', margin: '10px 0',textAlign: 'center' }}>{timeDiff.from2007.days} DAYS {timeDiff.from2007.hours} HOURS {timeDiff.from2007.minutes} MINS {timeDiff.from2007.seconds} S</p> 
        <p style={{ fontFamily: 'F4', fontSize: '40px',color:'pink', margin: '10px 0',textAlign: 'center' }}>{timeDiff.to2024.days} DAYS {timeDiff.to2024.hours} HOURS {timeDiff.to2024.minutes} MINS {timeDiff.to2024.seconds} S</p> 
      </Col>
      </Row>



  </div>

  )
    
  
}
