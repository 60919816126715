//登陆显示
import React from 'react'
import { Form, Input, Button,  Card ,notification,Avatar} from 'antd'
import { MailTwoTone, LockTwoTone, } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import {useDispatch,useSelector } from 'react-redux'



import './index.css'


export default function Login() {
    const userstate = useSelector((state) => {
        return state
    })
    const { Meta } = Card;
    const dispatch = useDispatch()
    const openmsg = (type,title,inf,time)=> {
        if(type==='error')time=30
        if(type==='warning')time=30
        if(type==='success')time=10
        notification[type]({
          message: title,
          description:inf,
          duration: time,
        });
      };
    const navigate = useNavigate();
    const onFinish = (values) => {
        if(values.password==="20240131"){
            dispatch({type: 'login',})
            navigate("/home");

            
        }else 
        if(values.password==="20240422"){
          dispatch({type: 'login',})
          navigate("/birthday");

          
      }else
        
        openmsg('success','','密码不对哦! ');
        
        //
    };
    

  return <Card cover={
    <img
      alt="example"
      src="https://images.chinatimes.com/newsphoto/2017-02-14/656/20170214004399.jpg"
    />}
     className="login-form">

    <Meta
      avatar={<Avatar src="https://img.touxiangwu.com/uploads/allimg/2021112612/xpfpr0vl5kl.jpg" />}
      title="让我看看你是谁！"
      description="以后敬敬的后面有我在，给我记住了！"
    />
    <br></br>
  <Form
      name="normal_login"
      onFinish={onFinish}
      initialValues={{
          remember: true,
      }}
     >
      <Form.Item
          name="password"
      >
          <Input.Password
              prefix={<LockTwoTone className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
          />
      </Form.Item>
        
      <Form.Item> 
          <Button type="primary" htmlType="submit" className="login-form-button" block>
             UnLock
          </Button>
      </Form.Item>
  </Form>
</Card>;
}
