import React, { Component,lazy,Suspense } from 'react';
import { Layout,BackTop} from 'antd';
import {
  Routes,
  Route,
} from 'react-router-dom'
import './index.css';
import NotFound from '../../NotFound'
import AHeader from '../../components/Header'
import AFooter from '../../components/Footer'
import Loading from '../../components/Loading';
import Timeline from '../TimeLimepg';
import Sex from '../Sexpg';
import Main from '../Mainpg';
import List from '../Listpg';
const { Content} = Layout;
const Envelope = () => {

  return (


  <Layout  className='bgc'>
    <AHeader />
    <div className='bgc'>
    <Content  style={{ margin: ' 5px 16px'}} >
    <BackTop />
    <Suspense fallback={<Loading/>}>

    <Routes>
    <Route index element = {<Main/>}/> 
          <Route path="main"  element = {<Main/>}/> 
          <Route path="timeline"  element = {<Timeline/>}/> 
          <Route path="list"  element = {<List/>}/> 
          <Route path="sex"  element = {<Sex/>}/> 
          <Route path="*" element={<NotFound />} />
    </Routes>

    </Suspense>
    </Content>
    <AFooter/>
    </div>
  </Layout>


  );
};

export default Envelope;
