//首页
import React from 'react'
import { Card ,Col, Row,Image,Collapse,Typography,Divider} from 'antd'
import './index.css'
import {Link} from 'react-router-dom'
import {
  ClockCircleOutlined,
  SmileTwoTone,
  RocketTwoTone,
  CodeTwoTone ,
  HeartTwoTone,
  CloudTwoTone,
  PhoneFilled,
  QqOutlined,
  WomanOutlined,
  ManOutlined,
  CloudOutlined,
CreditCardTwoTone, 
FireOutlined,
BugFilled,
HeartOutlined,
NotificationOutlined,
WechatFilled ,
LoadingOutlined
} from '@ant-design/icons';
import { Timeline } from 'antd';
const { Title } = Typography;
export default function timelinepg() {
  const { Panel } = Collapse;
  return (
    <div type="ncentera" style={{ fontFamily: 'F2', fontSize: '30px', margin: '10px 0', height:'1200px'}}  className='background'>
      
      <Divider />
      <Divider />
      <Card style={{ 

    left: '15%', width: '70%', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
      
      <p style={{ fontFamily: 'F2', fontSize: '80px',color:'black', margin: '10px 0',textAlign: 'center' }}>时间线</p>
      <Timeline mode="alternate">
      <Timeline.Item dot={
        <WomanOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }color="red"><font face="F4" size="4"  >1997-04-22</font> <font face="F3" size="4"  >宇宙无敌美少女可敬诞生 </font></Timeline.Item>
      <Timeline.Item dot={
        <ManOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }color="blue"><font face="F3" size="4"  >相貌平平洛宾出生 </font><font face="F4" size="4"  >1997-05-21</font></Timeline.Item>
    <Timeline.Item dot={
        <SmileTwoTone
          style={{
            fontSize: '16px',
          }}
          twoToneColor="#eb2f96"
        />
      }><font face="F4" size="4"  >2007-09-01</font><font face="F3" size="4"  > 相识日</font> </Timeline.Item>
    <Timeline.Item color="green"><font face="F3" size="4"  >命运让我们加上了联系方式</font> <font face="F4" size="4"  >2009-10-01</font></Timeline.Item>
    <Timeline.Item 
    dot={
      <QqOutlined
        style={{
          fontSize: '16px',
        }}
      />
    }><font face="F4" size="4"  >2012-08-20 </font><font face="F3" size="4"  >几经波折又联系上了QQ</font> </Timeline.Item>
    <Timeline.Item  dot={
      <PhoneFilled
        style={{
          fontSize: '16px',
        }}
      />
    }color="green"><font face="F3" size="4"  >交换了电话 </font><font face="F4" size="4"  >2016-07-19</font></Timeline.Item>
    <Timeline.Item dot={
      <CloudTwoTone
        style={{
          fontSize: '16px',
        }}
      />
    }><font face="F4" size="4"  > 2017-11-25</font> <font face="F3" size="4"  >周公的第一次暗示</font> </Timeline.Item>
    <Timeline.Item 
    dot={
      <WechatFilled
        style={{
          fontSize: '16px',
        }}
        
      />
    }color="green"><font face="F3" size="4"  >神奇的加上了微信</font> <font face="F4" size="4"  >2020-10-05</font></Timeline.Item>
    <Timeline.Item 
    dot={
      < CloudOutlined
        style={{
          fontSize: '16px',
        }}
        
      />
    }color="blue"
    ><font face="F4" size="4"  >2023-07-24</font> <font face="F3" size="4"  >周公的第二次暗示</font> </Timeline.Item>
    <Timeline.Item
      dot={
        <FireOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }
    color="red">
     <font face="F3" size="4"  >新年快乐日 </font> <font face="F4" size="4"  >2024-01-05 </font>
    </Timeline.Item>
    <Timeline.Item
      dot={
        <HeartOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }
    color="red">
      <font face="F4" size="4"  >2024-01-31</font>  <font face="F3" size="4"  >网恋表白日 </font>
    </Timeline.Item>
    <Timeline.Item
      dot={
        <BugFilled
          style={{
            fontSize: '16px',
          }}
        />
      }
    color="red">
        <font face="F3" size="4"  >报复日/沟通日</font> <font face="F4" size="4"  >2024-02-16</font>
    </Timeline.Item>

    <Timeline.Item
      dot={
        <HeartOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }
    color="red">
      <font face="F4" size="4"  >2024-2-21 </font><font face="F3" size="4"  >云爱日 </font>
    </Timeline.Item>

  <Timeline.Item
      dot={
        <HeartOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }
    color="red">
  <font face="F3" size="4"  >放纵日 </font> <font face="F4" size="4"  >2024-3-22 </font>
    </Timeline.Item>




    <Timeline.Item
      dot={
        <LoadingOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }
    >
      
      <font face="F3" size="4"  >故事待续</font>



    </Timeline.Item>
  <Timeline.Item
      dot={
        <HeartOutlined
          style={{
            fontSize: '16px',
          }}
        />
      }
    color="red">
     <font face="F4" size="4"  >70</font><font face="F3" size="4"  >寿辰交配日  <font face="F4" size="4"  >2067-4-24 </font></font>
    </Timeline.Item>
  </Timeline>
  </Card>


  </div>

  )
    
  
}
