//admin footer 检测权限在这。
import React ,{ useEffect } from 'react'
import { Footer } from 'antd/lib/layout/layout';
import { useSelector} from 'react-redux'
import { useNavigate } from "react-router-dom";
import {notification } from 'antd'
export default function AFooter() {
  const openmsg = (type,title,inf,time)=> {
    time=10
    notification[type]({
      message: title,
      description:inf,
      duration: time,
    });
  };
  const navigate = useNavigate();
  const userstate = useSelector((state) => {
    return state
  })
  useEffect(() => {
    if(userstate.PW.PW==='1314'){
      openmsg('error','','Please login!');
       navigate("/login");
     }
  },[]);
  return (
    <div style={{ backgroundColor: '#070026', height:'500px '}}></div >
  )
}
